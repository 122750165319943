/*
 * Bones Scripts File
 * Author: Eddie Machado & Todor Karastoyanov
*/

const menuOperations = (e, k, d, m, j) => {
	const closeOnClick = document.querySelectorAll('.header-nav-menu-list a')
	window.addEventListener('click', (f)=>{
		var ft = f.target
		if(ft == e) {
			if(ft.classList.contains(d)) {
				document.body.classList.remove(j)
				k.classList.remove(m)
				ft.classList.remove(d)
			}
			else {
				document.body.classList.toggle(j)
				k.classList.toggle(m)
				ft.classList.toggle(d)
			}
		}
		
		closeOnClick.forEach((c)=>{
			if(ft == c) {
				document.body.classList.remove(j)
				k.classList.remove(m)
				ft.classList.remove(d)
			}
		})
	})
}

menuOperations(
	document.querySelector('.menu-nav-button'), 
	document.querySelector('.header-nav-section'), 
	'open-nav-button', 
	'open-nav-section', 
	'open-nav-body'
)

function watchForHover(container) {
    var hasHoverClass = false,
        lastTouchTime = 0;
    function enableHover() {
        if (new Date() - lastTouchTime < 500) return;
        if (hasHoverClass) return;
        container.className += ' has-hover';
        hasHoverClass = true;
    }
    function disableHover() {
        if (!hasHoverClass) return;
        container.className = container.className.replace(' has-hover', ' has-no-hover');
        hasHoverClass = false;
    }
    function updateLastTouchTime() {
        lastTouchTime = new Date();
    }
    document.addEventListener('touchstart', updateLastTouchTime, true);
    document.addEventListener('touchstart', disableHover, true);
    document.addEventListener('mousemove', enableHover, true);
    enableHover();
}
watchForHover(document.querySelector('body'));

const toggleContainers = (a, b, c) => {
	let zli = document.createElement('LI')
		zli.setAttribute('class', 'close-li')
		
	let	zq = document.createElement('BUTTON')
		zq.setAttribute('type', 'button')
		zq.setAttribute('class', 'close')
		zq.innerHTML = 'close'
		
	b.classList.remove(c)
	a.addEventListener("click", ()=>{
			b.classList.add(c)
			zli.appendChild(zq)
			b.appendChild(zli)
		
	})
	zq.addEventListener("click", ()=>{
		if(b.classList.contains(c) ) {
			b.classList.remove(c)
		}
	})
}

const scrollToTop = () => {
	const c = document.documentElement.scrollTop || document.body.scrollTop;
	if (c > 0) {
		window.requestAnimationFrame(scrollToTop);
		window.scrollTo(0, c - c / 12);
	}
}
document.querySelector('.back-to-top').addEventListener('click', function(){
	scrollToTop();
})

const scrollFromTop = (d) => {
	const c = document.documentElement.scrollTop || document.body.scrollTop;
	(c > 300) ? d.classList.add('show') : d.classList.remove('show');
}

window.addEventListener('scroll', function(){
	scrollFromTop(document.querySelector('.back-to-top'));
})

const accordion = (e) => {
	e.forEach((f)=>{
		let nexte = f.nextElementSibling
		f.addEventListener('click', (h)=>{
				let ht = h.target
			ht.classList.toggle('toggler')
			ht.nextElementSibling.classList.toggle('toggled')
		})
	})
}

window.addEventListener("DOMContentLoaded", () => {
	toggleContainers(document.querySelector('.search-in-menu > a'), document.querySelector('.search-in-menu .sub-menu'), 'expand-search' )
	accordion(document.querySelectorAll('.accordion-title'))
});